import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import cn from 'classnames';
import { getCurrentMobilePosition, getCurrentPosition, getLocation } from '../../_helpers/currentPosition';
import { alertsConstants, TRANSLATIONS } from '../../_constants';

import geoSvg from '../../assets/images/geo.svg';
import LocationConfirmModal from '../../_modals/LocationConfirmModal';
import st from './AutocompleteProfile.module.scss';
import Input from '../Input/Input';

class AutocompleteProfile extends Component {
  constructor(props) {
    Geocode.setApiKey(`AIzaSyDzIxZZJwdaEB3zF4uxVMa-pZZYL2oT_Sc&language=${props.language}`);
    Geocode.enableDebug();
    super(props);
    this.state = {
      address: this.props.address ? this.props.address : '',
      selectedAddress: this.props.address ? this.props.address : '',
      geoStatus: '',
      isOpen: false,
      placeholder: this.props.placeholder || '',
    };
  }

  componentDidMount() {
    if (this.props.status === 'mobile') {
      document.addEventListener('message', this.handleEvent);
      window.addEventListener('message', this.handleEvent);
    }

    const isMobile = (innerHeight > innerWidth && innerWidth <= 428) || (innerHeight < innerWidth && innerWidth <= 926);

    if (isMobile) {
      // TODO: Uncomment after fixes the nearby from mobile
      // this.props.status === 'mobile'
      //   && window.ReactNativeWebView.postMessage('hasGeolocationPermission');
    } else {
      getLocation(this.props.dispatch);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('message', this.handleEvent);
    window.removeEventListener('message', this.handleEvent);
  }

  handleEvent = (message) => {
    const data = JSON.parse(message.data);
    if (data?.type === 'location') {
      if (data?.status) {
        try {
          this.handleGeocode({ coords: { latitude: data.coords.lat, longitude: data.coords.lng } });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setState({ isOpen: true, geoStatus: 'denied' });
      }
    }
  };

  handleChange = (address) => {
    const { onChange } = this.props;

    if (!address) {
      this.setState({ address: '', selectedAddress: '' });
      onChange('address', '');
      onChange('city', '');
    }

    this.setState({ address });
  };

  handleSelect = (address) => {
    const { onChange, validateForm, validate, language } = this.props;

    this.setState({ address, selectedAddress: address });

    geocodeByAddress(address)
      .then((results) => {
        let getLocality = results[0].address_components.filter((el) => el.types && el.types.includes('locality'));

        if (!getLocality.length) {
          getLocality = results[0].address_components.filter((el) => el.types && el.types.includes('political'));
        }

        onChange('address', address);
        onChange('city', getLocality.length ? getLocality[0].long_name : results[0].address_components[0].long_name);

        if (validateForm) validateForm();
      })
      .catch((error) => {
        if (!this.props.address) {
          validate('address', TRANSLATIONS[language].error_address);
        }
      });
  };

  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  handleErrorGeoPosition = (error) => {
    // !TODO: Create hadle error
    console.log(error);
  };

  handleGeocode = (location) => {
    if (
      !location ||
      !location.coords ||
      location.coords.latitude === undefined ||
      location.coords.longitude === undefined
    ) {
      console.error('Invalid coordinates:', location);
      return;
    }

    const { latitude, longitude } = location.coords;

    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const { results } = response;
        if (!results || results.length === 0) {
          console.error('No results from geocoding.');
          return;
        }

        const newAddress = results[0].formatted_address;
        const addressComponents = results[0].address_components || [];

        const getLocality = addressComponents.find((el) => el.types.includes('locality'));
        const getAdministrative = addressComponents.find((el) => el.types.includes('administrative_area_level_1'));
        const getCountry = addressComponents.find((el) => el.types.includes('country'));

        const city = getLocality?.long_name || getAdministrative?.long_name || getCountry?.long_name || '';

        this.props.onChange('address', newAddress);
        this.props.onChange('city', city);

        this.setState({
          address: newAddress,
          selectedAddress: newAddress,
          coordsDefault: { latitude, longitude },
          coords: { latitude, longitude },
        });
      },
      (error) => {
        console.error('Geocoding error:', error);
        this.props.dispatch({ type: alertsConstants.LOCATION, error });
      },
    );
  };

  handleCheckGeoPermissions = () => {
    if (this.props.status === 'mobile') {
      window.ReactNativeWebView.postMessage('hasGeolocationPermission');
    } else {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          getCurrentPosition(this.handleGeocode);
          navigator.geolocation.getCurrentPosition(this.handleGeocode, this.handleErrorGeoPosition, {
            enableHighAccuracy: true,
          });
        } else if (result.state === 'prompt') {
          getCurrentPosition(this.handleGeocode);
          navigator.geolocation.getCurrentPosition(this.handleGeocode, this.handleErrorGeoPosition, {
            enableHighAccuracy: true,
          });
          this.setState({ isOpen: true, geoStatus: 'prompt' });
        } else if (result.state === 'denied') {
          this.setState({ isOpen: true, geoStatus: 'denied' });
        }
      });
    }
  };

  onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  handleBlur = () => {
    const { selectedAddress, address } = this.state;
    const { validateForm, onChange } = this.props;

    if (!selectedAddress && address) {
      this.setState({ address: '' });
    }

    validateForm();
    onChange('address', selectedAddress);
    this.setState({ address: selectedAddress });
  };

  render() {
    const { coordsDefault, coords, address, placeholder } = this.state;
    const { errorClass, language } = this.props;
    const english = language === 'en';

    return (
      <>
        <PlacesAutocomplete value={address} onChange={this.handleChange} onSelect={this.handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className={cn('AutocompleteProfile', { [st.rtl]: language === 'he' })}>
              <Input
                styles={cn(st.input, errorClass)}
                name="address"
                {...getInputProps({
                  onBlur: this.handleBlur,
                  autoComplete: 'f',
                  className: 'location-search-input',
                  placeholder,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {suggestions.map((suggestion) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      key={suggestion.index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>

              <div
                role="button"
                className={cn(
                  st.inputIcon,
                  english
                    ? `geoEN ${!coordsDefault || coordsDefault.lng !== coords.lng ? ' geoEN-no' : ''}${this.props.location !== 'ok' && ' geo-disabled'
                    }`
                    : `geo ${!coordsDefault || coordsDefault.lng !== coords.lng ? ' geo-no' : ''}${this.props.location !== 'ok' && ' geo-disabled'
                    }`
                )}
                onClick={() => this.handleCheckGeoPermissions()}
              >
                <img src={geoSvg} />
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <LocationConfirmModal
          language={language}
          geoStatus={this.state.geoStatus}
          isOpen={this.state.isOpen}
          onClose={this.handleCloseModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.locale,
  location: state.alertsReducer.location,
  status: state.applicationsReducer.status,
});

export default connect(mapStateToProps)(AutocompleteProfile);
