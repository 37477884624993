import React from 'react';

export const TRANSLATIONS = {
  en: {
    'You can resize the picture': 'You can resize the picture',
    save: 'save',
    radius: 'Radius',
    remove: 'remove',
    edit_card: 'edit card',
    no_items: 'No items',
    cardHolder: 'Cardholder name',
    Su: 'Su',
    Mo: 'Mo',
    Tu: 'Tu',
    We: 'We',
    Th: 'Th',
    Fr: 'Fr',
    Sa: 'Sa',
    cardNumber: 'Card number',
    share_on_facebook: 'Share on Facebook',
    share_on_whatsApp: 'Share on WhatsApp',
    share_on_telegram: 'Share on Telegram',
    attach_files_req: 'Attach files is required',
    expires: 'Exp.date',
    cvv: 'CVV',
    save_address: 'Save this address for future purchases',
    cardHolder_req: 'Card holder is required',
    cardNumber_req: 'Card number is required',
    expires_req: 'Expires date is required',
    cvv_req: 'Cvv is required',
    fullName_req: 'Full name is required',
    streetAddress_req: 'Street address is required',
    city_required: 'City is required',
    state_req: 'State is required',
    zipCode_req: 'Zip code is required',
    fullName: 'full Name',
    streetAddress: 'street Address',
    state: 'state',
    zipCode: 'zip Code',
    saveCard: 'Save card',
    such_phone_number_exist: 'Such phone number exist',
    open_with: 'Open with',
    by_online: 'By Online',
    by_in_store: 'By in Store',
    quick_view: 'Quick view',
    qr_app_title: 'Download Our App',
    qr_app_desc: 'Chipper saves many discount coupons for thousands of products.  Just download, realize and save.',
    nearby_additional_text:
      'At any given moment there are lots of  hot coupons around you. Just enter an address and we will find them for you   ',
    get_you_app: 'Get our App',
    description:
      "With we want to optimize the customization process so your team can save time when building websites. Never miss a lower price on Lorem Ipsum – we’ll compare every seller to find you the best possible deal. And yes, it even works with Chipper! Have your eye on something specific? Add it to your list and we’ll notify you when the price drops. Still searching for promo codes? We'll instantly find and apply the Internet's best codes directly to your cart.",
    title: 'Products participating',
    menu_1: 'Hot deals',
    menu_2: 'Categories',
    menu_3: 'Nearby',
    menu_4: 'Customer Service',
    menu_5: 'Private area',
    menu_6: 'Preferred categories',
    menu_7: 'Settings',
    menu_8: 'About Chipper',
    menu_9: 'Register as a company',
    describe_register_company:
      'Chipper transfers power and control into your own hands, manufacturers, wholesalers and business owners. From today you can independently produce Chipper coupons, run campaigns, contact end customers directly and increase sales, in a simple and convenient way. <br /> <br />Join for free, with no obligation and no set-up fee, and pay a commission only when your Chipper coupons are redeemed. No matter how you look at it, you have won. You choose the products and the discount, and we give you a new platform and way to sell to your customers and new audiences by location, categories. <br/><br/> And customized.',
    menu_10: 'Help',
    menu_11: 'Contact Us',
    menu_12: 'Preferred categories',
    menu_13: 'Suitable for me',
    menu_14: 'Help center',
    menu_15: 'Our Application',
    menu_16: 'System messages',
    offers_according: 'Hot offers according to selected categories.',
    notifications_according:
      'System notifications such as: password reset,<br />realization reminders, customer support feedback.',
    title_about:
      "With we want to optimize the customization process so your team can save time when building websites. Never miss a lower price on Lorem Ipsum – we’ll compare every seller to find you the best possible deal. And yes, it even works with Chipper! <br /> <br /> Have your eye on something specific? Add it to your list and we’ll notify you when the price drops. Still searching for promo codes? We'll instantly find and apply the Internet's best codes directly to your cart.",
    companies: 'Publish with us',
    search: 'Search...',
    search_transaction: 'Search transaction',
    prev_page: 'Back to previous page',
    company_name: 'Company name:',
    company: 'Company',
    purchase_date: 'Purchase date',
    payment_amount: 'Payment amount',
    payment_method: 'Payment method',
    status: 'Status',
    no_transactions: 'You don’t have any transaction yet',
    transactions_message: 'New transactions will apear here',
    no_payments: 'You don’t have any Payment methods',
    payments_message: 'Add card and create get more opportunities',
    add_payment: 'Add payment method',
    add_payment_message: 'Add new payment method',
    coupon_number: 'Coupon number',
    realization_in_store: 'Realization in store',
    realization_online: 'Realization Online',
    realized: 'Realized',
    deals_end: 'The deal ends',
    time: 'Time',
    branches: 'Branches',
    fine_print: 'The Fine Print',
    terms_of_use: 'Terms of Use',
    following_branches: 'The coupon can be redeemed at the following branches:',
    products_title: 'Products participating in coupon',
    sku: 'Product SKU:',
    interesting_deals: 'More deals that may be interest you',
    discount: 'Discount',
    realized_card: 'realized',
    realized_card_one: 'realized',
    days_left: 'num1 days left',
    day_left: 'num1 day left',
    look_online: 'Look for us online',
    title_menu_1: 'Main',
    title_menu_2: 'Private area',
    title_menu_3: 'General',
    privacy_policy: 'Privacy Policy',
    accessibility_statement: 'Accessibility Statement',
    terms: 'Terms of Use',
    rights_reserved: 'All rights reserved to Chipper © year',
    show_more: 'Show more',
    home: 'Home',
    categories: 'Categories',
    in: 'In',
    big_file: 'File is too big! Size should be no more than 5mb',
    days: ' days',
    day: ' day',
    no_branches: 'All branches',
    help: 'Questions and Answer',
    menu_help: 'Questions & Answer',
    account: 'My account',
    notification_title: 'Notifications',
    my_acc: 'Account',
    saved_coupons: 'Saved Coupons',
    clear_all_coupons: 'Clear all coupons',
    settings: 'Settings',
    attach_files: 'Attach files',
    error_first_name: 'First name is required!',
    error_full_name: 'Full name is required!',
    error_company_name: 'Full company is required!',
    error_last_name: 'Last name is required!',
    error_phone: 'Phone is required!',
    error_phone_match: 'Phone is incorrect!',
    error_city: 'City is required!',
    city_invalid: 'Please enter a valid city',
    error_street: 'Street is required!',
    error_birth: 'Birth date cannot be more than today!',
    error_birth_match: 'Please enter a valid date',
    login: 'Sign in',
    registration: 'Complete your registration',
    sign_in_text: 'How do you want to sign in?',
    sign_up_text: 'Please enter your personal information to complete registration',
    sign_up_agree1_1:
      'I know that registration on the site is conditional on joining the Chipper customer club. I confirm that I’ve read the',
    sign_up_agree1_2: 'of the site, including the',
    sign_up_agree1_3: 'applicable to them.',
    sign_up_agree2:
      'I agree to receive updates, coupons, benefits, promotions and advertisements for SMS, email or any other communication channel, Chipper and/or companies associated with it.',
    email: 'Email:',
    email_adr: 'Email address:',
    can_we_help: 'How can we help?',
    alerts: 'Alerts',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    to_continue: 'to continue',
    wishlist: 'Wishlist',
    or: 'or',
    password: 'Password:',
    or_login: 'Or Login with',
    create_account: 'Create Account',
    not_registered: 'Not Registered?',
    profile_menu_1: 'My profile',
    profile: 'Profile',
    profile_menu_2: 'Preferred categories',
    profile_menu_3: 'Saved Coupons',
    profile_menu_4: 'Settings',
    profile_menu_5: 'Logout',
    profile_menu_6: 'Payment & Transactions',
    account_payments: 'Check your payments list',
    clear_all: 'Clear All',
    list_coupons: 'List of coupons you added to wishlist:',
    no_coupons: 'No coupons',
    account_settings: 'Account settings',
    change_settings: 'Change your account settings',
    change_avatar: 'Change avatar',
    required_avatar: 'Upload JPG, GIF or PNG image. 300 x 300 required.',
    first_name: 'First Name',
    last_name: 'Last Name',
    message: 'Message',
    submit: 'Submit',
    subject: 'Subject',
    subjectOther: 'Other',
    phone: 'Phone:',
    phone_number: 'Phone number:',
    city: 'City',
    street: 'Street',
    male: 'Male',
    female: 'Female',
    date_of_birth: 'Date of birth',
    postal_code: 'Postal code',
    save_changes: 'Save changes',
    has_been_added: 'Coupon has been added to saved coupons!',
    has_been_removed: 'Coupon has been removed from saved coupons!',
    confirm_delete_title: 'Confirm delete wishlist',
    confirm_delete_question: 'Do you want to remove all coupons from your wishlist?',
    cancel: 'Cancel',
    cancelation: 'Cancel',
    select: 'Select',
    delete: 'Delete',
    you_are_not_logged_in: 'You are not logged in.',
    access_denied: 'Access Denied.',
    not_found: 'Not Found.',
    something_went_wrong: 'Oops... Something went wrong.',
    favourite_categories: 'Favourite categories',
    favourite_categories_list: 'favourite categories',
    new: 'New',
    amount: 'Amount of realizations',
    about_to: 'About to end',
    chipper_me: 'Chipper me',
    top_categories: 'Top categories',
    view_all: 'View all',
    were_selected: 'favorite categories were selected',
    result_found: 'total results found',
    results_total: 'results found',
    search_term: 'Search result for:',
    change_categories: 'Change categories',
    your_list: 'You have *value categories from your',
    no_categories_1: 'In order for us to show you the chips that will really save you, you must choose',
    no_categories_2: 'The categories that interest you',
    reset_password: 'Reset Password',
    question_reset_password: 'How do you want to reset your password?',
    via_sms: 'via phone:',
    get_sms: 'Get an SMS',
    get_email: 'Get an email',
    receive_text: 'Receive a text',
    receive_sms: 'Receive a SMS',
    via_email: 'via email:',
    receive_email: 'Receive an email',
    security_code: 'Enter security code',
    resend: 'Resend',
    reset_my_password: 'Reset My Password',
    create_password: 'Create password',
    continue: 'Continue',
    send_code: 'Send code',
    savings: 'Yearly Average Savings',
    merchants: 'Participating Merchants',
    discount_avg: 'Average Discount',
    honey: 'What Honey members are getting:',
    sent_sms: 'We’ve sent an SMS with an activation code to your phone',
    sent_mail: 'We’ve sent an activation code to your email: ',
    verification: 'Verification',
    enter_phone: 'Enter your Phone',
    enter_email: 'Enter your Email Address',
    your_code: 'Type in your code',
    security_code_example: 'We sent a special 6-digit security code to your email',
    another_way: 'Try Another Way',
    unique_password: "Choose a password that's hard to guess and unique to this account.",
    all_set: "You're all set!",
    can_login: 'You can now log in using your new password.',
    lost_password: 'Lost password?',
    your_email: 'Your email',
    your_phone: 'Your phone',
    new_pass: 'New password',
    digits_6: 'Code should consist 6-digits',
    pass_required: 'No password provided.',
    pass_short: 'Password is too short - should be 8 chars minimum.',
    pass_contain: 'Password can only contain Latin letters and numbers.',
    register: 'Register',
    full_name: 'Full name:',
    comments: 'Comments',
    thanks: 'Thank You!',
    sent_success: 'Your request was sent successfully.',
    contact_soon: 'We will contact you very soon!',
    ok: 'OK',
    register_coupon: 'Register as a coupon manufacturer',
    in_touch: "Haven't found the answer?",
    choose_file_to_attach: 'Choose file to attach',
    max_size_file: 'Max size file',
    help_text_file: 'You can download file types',
    error_file: 'The file is too big. Its size cannot exceed 5MB.',
    get_in_touch: 'Get in touch',
    love_to_help_you: "We'd love to talk about how we can help you.",
    help_you: 'Contact us and we will get back to you as soon as possible',
    email_address: 'Email addresses',
    phone_numbers: 'Phone numbers',
    main_store: 'Main store address',
    tech_support: 'Tech support: ',
    customer_support: 'For customers: ',
    home_page: 'Home Page',
    home_text: 'Return to homepage',
    search_page: 'Search',
    search_text: 'Find with advanced search',
    help_page: 'Help & Support',
    help_text: 'Visit our help center',
    cant_see: "We can't seem to find the page you are looking for.",
    download_app: 'Download our app',
    download_on: 'Download on the',
    about: 'About',
    enter_number: 'Enter you phone number',
    our_coupons: 'Our coupons',
    confirm_age: 'Confirm your age',
    access_geo_location: 'Access geolocation',
    access_geo_location_prompt: 'Please, allow access for location.',
    access_geo_location_denied:
      'Your access for geolocation is blocked, please, turn it on your device or browser for accurate information near you.',
    must_be_18: 'You must be 18 or older to use this coupon',
    over_18: 'I’m Over 18',
    error_email: 'Email is required!',
    category_removed: 'Category was removed from your list',
    category_added: 'Category added to your list',
    profile_avatar_error: 'Image has incorrect resolution.',
    list_view: 'Switch to List view',
    map_view: 'Switch to Map view',
    details: 'Details',
    results: 'results',
    address: 'Address',
    gender: 'Gender',
    error_address: 'Address is required',
    error_gender: 'Gender is required!',
    no_address: "You don't have your address",
    no_address_text: "You don't have your address in profile, please setup",
    set_address: 'Set address',
    add_to_saved_coupons: 'Add to the saved coupons',
    remove_from_saved_coupons: 'Remove from the saved coupons',
    please_display_QR: 'Please display the QR code to the provider',
    use_a_coupon_number: 'Or use a coupon number',
    categories_selected_1: 'You have selected ',
    categories_selected_2: ' categories',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    no_categories_selected: 'You have not yet selected any categories to see chips that really interest you',
    error_message: 'Message is required',
    error_subject: 'Subject is required!',
    filter_by: 'Filter by:',
    no_coupons_title: 'There are currently no chips in this provider',
    no_coupons_desc: "Let's look at what's going on in",
    no_coupons_desc_other: 'other providers?',
    cancel_18: 'Cancel',
    no_coupons_saved_1: 'You have not saved any chips yet',
    no_coupons_saved_2: "And that means you have not saved with us yet. Too bad, it's worth every penny :)",
    no_coupons_saved_3: 'Clicking on the heart icon will save you chips for realization.',
    error_auto_complete: 'What address did you think of?',
    no_coupons_field: 'We did not find any chips in your area. It is recommended to increase the search range',
    spec_deals: 'Special deals',
    spec_details: 'Special details',
    back: 'Back',
    notifications: 'Notifications',
    resend_pass: 'resend code',
    no_notifications: 'No notifications right now',
    helpSubjectPlaceholder: 'Select the reference topic',
    notification_name_days: 'couponName couponNumber will expire in daysEx days',
    notification_name_day: 'couponName couponNumber will expire in daysEx day',
    notification_name_today: 'couponName couponNumber will expire today',
    created_at: 'day days ago',
    today: 'Today',
    back_button: 'Back',
    without_limitation: 'Without limitation',
    once_an_hour: 'Once an hour',
    once_a_day: 'Once a day',
    once_a_week: 'Once a week',
    once_a_month: 'Once a month',
    email_notification: 'Email notifications',
    sms_notification: 'SMS notifications',
    error_email_match: 'Email must be a valid!',
    member_no: 'Member ID',
    done: 'Done!',
    approve_payment:
      'New payment method has been successfully added 😉 As usually card approval takes up to 30 minutes.',
    code_pos: 'Please display this code to the provider',
    limited_time: 'Time',
    limited_time_desc: 'Limited time',
    no_coupons_title_result: "We have no coupons for this product, let's try another one 🙂",
    no_coupons_desc_result: 'Can we chip you with another product?',
    agree1:
      'I know that registration on the site is conditional on joining the chipper customer club. I confirm that I have read the *terms* of the site, including the privacy policy applicable to them.',
    agree2:
      'I agree to receive updates, coupons, benefits, promotions and advertisements for mobile, email or any other communication channel, Chipper and / or companies associated with it.',
    required: 'It is a required field',
    weak_alcohol_text: 'Contains alcohol, it is recommended to avoid excessive drinking!',
    strong_alcohol_text: 'Excessive alcohol consumption. Life-threatening and harmful to health!',
    agreements_are_required: 'Terms of use are required',
    approve_agreements: 'You should approve terms of use',
    to_profile: 'Go to profile',
    faq: 'frequently asked questions',
    open_faq: 'Open Faqs',
    deleting_status: 'Deleting status',
    km: 'km',
    wrong_code: 'Please enter a valid code',
    wrong_phone: 'Please enter a valid phone number.',
    saved_profile: 'Profile saved!',
    valid_phone: 'Phone should be entered without 0',
    saved_modal: 'Welcome to Chipper',
    saved_modal_button: 'OK',
    labelRangeKm: 'km',
    priceBeforeDiscount: 'Price per unit before discount:',
    savedProfile: 'Your information was saved',
    ending_today: 'Ending today',
    image_for_illustration_only: 'Image for illustration only',
    coupon_for_register_company: 'Company name:',
    transactions_history: 'TRANSACTIONS HISTORY',
    transactions: 'TRANSACTIONS',
    payment_methods: 'PAYMENT METHODS',
    show_all: 'Show all',
    show_more: 'Show more',
    hot_deals: 'Hot deals',
    poplar_categories: 'Popular categories',
    how_it_works: 'How it works ?',
    use_desktop_or_mobile: 'Use desktop or mobile app',
    setup_an_account: 'Setup an account',
    explore_the_products: 'Explore the products',
    use_chipper_number_to_get_discount: 'Use Chipper number to get your discount',
    news: 'News',
    coupons_about_to_end: 'Coupons about to end',
    offers: 'Offers',
    shop_by_providers: 'Shop by providers',
    coupons: 'Coupons',
    main_menu: 'Main menu',
    all_categories: 'All categories',
    discount: 'Discount',
    show_all_coupons: 'Show all coupons',
    popular_coupons: 'Popular coupons',
    on_date: 'on',
    pricePerUnit: 'Price per unit:',
    why_us_big_title: 'Starting today, coupons will be distributed directly to the consumer',
    why_us_big_title_rest: 'Without intermediates!',
    why_us: 'Why Joining us ?',
    why_us_title_1: 'An innovative tool for DATA-based targeted marketing',
    why_us_title_2: `A simple, fast, and effective process aimed at lowering customers’ overall purchases and increasing suppliers’ sales`,
    why_us_title_3: 'With us everyone benefit',
    why_us_answer_1_1: 'Precise Market Segmentation - Tailored to age, location and interests',
    why_us_answer_1_2: 'Personalization - Personalized coupon offers based on learning consumer’s preferences',
    why_us_answer_1_3: 'Extracting Insights- Dashboards, Statistical reports, Detecting potential customers',
    why_us_answer_2_1: 'Available to All - Any business can produce coupons',
    why_us_answer_2_2: 'Full POS Integration- Without scanning, barcodes or delays',
    why_us_answer_2_3: 'Easy Website Integration - Full control with real-time effective and valuable management',
    why_us_answer_2_4: 'Full Controlm- Effective and valuable coupon management',
    why_us_answer_3_1: 'Join for free - No commitment No setup fees!',
    why_us_answer_3_2: 'Pay only when redeemed- If you didn’t sell, you won’t pay!',
    why_us_answer_3_3: 'Accessibility - Access customers easily and fast',
    deactivate_account: 'Delete account',
    remove_account_confirm: `Dear User, <br/> <br>

    We want to ensure that you are fully aware of the consequences before proceeding with deleting your profile. Please note that deleting your profile is an irreversible action. Once you confirm the deletion, all your data and profile information will be permanently erased from our system. <br><br>
    
    If you choose to sign up again in the future, you will be starting with a completely new profile. This new profile will not contain any data, history, or settings from your deleted profile. <br> <br>
    
    We recommend you to consider this decision carefully. If you have any concerns or questions, please feel free to reach out to us before proceeding. <br> <br>
    
    Thank you for your attention to this important matter, Chipper.`,
    are_you_sure: 'Are you sure?',
    publish_modal_text: 'Publish your business with us',
    publish_modal_button: 'Join',
    online: 'Online',
    distance: 'Distance',
    updateModalText: 'You are using outdated version. Please update!',
    update: 'Update',
    aboutUs: 'About us',
  },
  he: {
    'You can resize the picture': 'אתה יכול לשנות את גודל התמונה',
    save: 'לשמור',
    big_file: 'הקובץ גדול מדי! הגודל לא צריך להיות יותר מ-mb5',
    results_total: 'סך התוצאות',
    subjectOther: 'Other',
    remove: 'remove',
    edit_card: 'edit card',
    cardHolder: 'Cardholder name',
    cardNumber: 'Card number',
    expires: 'Exp.date',
    cvv: 'CVV',
    save_address: 'Save this address for future purchases',
    radius: 'רַדִיוּס',
    such_phone_number_exist: 'מספר טלפון כזה קיים',
    open_with: 'לפתוח עם',
    by_online: 'על ידי אונליין',
    by_in_store: 'על ידי בחנות',
    quick_view: 'מבט מהיר',
    you_are_not_logged_in: 'אתה לא מחובר.',
    access_denied: 'גישה נדחתה.',
    not_found: 'לא נמצא.',
    something_went_wrong: 'אופס! משהו השתבש.',
    qr_app_title: 'הורד את האפליקציה שלנו',
    qr_app_desc: "הורידו את אפליקציית  צ'יפר, ותשלמו פחות על מאות מוצרים",
    nearby_additional_text: 'בכל רגע נתון יש סביבך המון קופונים שווים. צריך רק להכניס כתובת ואנחנו נמצא לך אותם',
    get_you_app: 'השג את האפליקציה שלנו',
    description:
      'אנו רוצים לייעל את תהליך ההתאמה האישית כך שהצוות שלך יוכל לחסוך זמן בבניית אתרים. לעולם אל תחמיץ מחיר נמוך יותר ב- Lorem Ipsum - אנו נשווה בין כל מוכר כדי למצוא לך את העסקה הטובה ביותר. וכן, זה אפילו עובד עם צ&#39;יפר! האם העין שלך במשהו ספציפי? הוסף אותו לרשימה שלך ונודיע לך כאשר המחיר יירד. עדיין מחפש קודי פרומו? אנו נמצא ונחיל באופן מיידי את הקודים הטובים ביותר של האינטרנט ישירות על העגלה שלך.',
    title: "הצ'יפרים המשתתפים",
    access_geo_location: 'גישה למיקום',
    access_geo_location_prompt: 'בבקשה, אפשר גישה למיקום',
    access_geo_location_denied: 'הגישה שלך למיקום  חסומה, בבקשה, הפעל אותו לקבלת מידע מדויק בקרבתך',
    january: 'ינואר',
    february: 'פברואר',
    march: 'מרץ',
    april: 'אפריל',
    may: 'מאי',
    june: 'יוני',
    july: 'יולי',
    august: 'אוגוסט',
    september: 'ספטמבר',
    october: 'אוקטובר',
    november: 'נובמבר',
    december: 'דצמבר',
    menu_1: "חם בצ'יפר",
    menu_2: 'קטגוריות',
    menu_3: 'קופונים בסביבה שלך',
    menu_4: 'אפשר לעזור?',
    menu_5: 'אזור אישי',
    menu_6: 'קטגוריות שנבחרו',
    menu_7: 'הגדרות',
    menu_8: " הסיפור של צ'יפר",
    menu_9: 'הצטרפות כפרטנר',
    describe_register_company:
      "צ'יפר מעבירה את הכוח והשליטה לידיים שלכם, היצרנים, הסיטונאים ובעלי העסקים. מהיום אתם יכולים לייצר באופן עצמאי קופוני צ'יפר, לנהל קמפיינים, לפנות ישירות ללקוחות הקצה ולהגדיל את המכירות, באופן פשוט ונוח.  <br /> <br /> מצטרפים בחינם, בלי התחייבות ובלי דמי הקמה, ומשלמים עמלה רק כשקופוני הצ'יפר שלכם ממומשים. איך שלא תסתכלו על זה, הרווחתם. אתם בוחרים את המוצרים ואת ההנחה, ואנחנו נותנים לכם פלטפורמה ודרך חדשה למכור ללקוחות שלכם ולקהלים חדשים לפי מיקום, קטגוריות    <br /> <br />ובהתאמה אישית.",
    menu_10: 'שאלות ותשובות',
    menu_11: 'יצירת קשר',
    menu_12: 'קטגוריות שבחרת',
    menu_13: 'קופונים בול בשבילך',
    menu_14: 'אפשר לעזור?',
    menu_15: 'היישום שלנו',
    menu_16: 'הודעות מערכת',
    spec_details: 'דילים מיוחדים',
    offers_according: 'דילים חמים לפי הקטגוריות שבחרת ',
    notifications_according:
      'System notifications such as: password reset,<br />realization reminders, customer support feedback.',
    title_about:
      "With we want to optimize the customization process so your team can save time when building websites. Never miss a lower price on Lorem Ipsum – we’ll compare every seller to find you the best possible deal. And yes, it even works with Chipper! <br /> </br> Have your eye on something specific? Add it to your list and we’ll notify you when the price drops. Still searching for promo codes? We'll instantly find and apply the Internet's best codes directly to your cart.",
    companies: 'פרסמו אצלנו',
    search: 'על מה רצית לחסוך?',
    search_transaction: 'Search transaction',
    prev_page: 'חזור לדף הקודם',
    company_name: 'מפיק קופון:',
    coupon_number: 'קוד הקופון',
    realization_in_store: 'למימוש בחנות',
    realization_online: 'למימוש אונליין',
    realized: 'אנשים כבר מימשו',
    deals_end: 'העסקה מסתיימת',
    time: 'זמן',
    branches: 'סניפים',
    fine_print: 'האותיות הקטנות',
    terms_of_use: 'תנאי שימוש',
    following_branches: 'למימוש בסניפים הבאים:',
    products_title: 'מוצרים המשתתפים בקופון',
    sku: 'מק”ט מוצר:',
    interesting_deals: 'קופונים שיכולים לעניין אותך',
    discount: 'הנחה',
    realized_card: 'מומשו',
    realized_card_one: 'מומש',
    days_left: 'מסתיים בעוד num1 ימים',
    day_left: 'מסתיים בעוד num1 ימים',
    look_online: 'משתלם להישאר מעודכנים',
    title_menu_1: 'ראשי',
    title_menu_2: 'אזור אישי',
    title_menu_3: 'כללי',
    privacy_policy: 'מדיניות פרטיות',
    accessibility_statement: 'הצהרת נגישות',
    terms: 'תנאי שימוש',
    share_on_facebook: 'שתף בפייסבוק',
    share_on_whatsApp: 'שתפו בוואטסאפ',
    share_on_telegram: 'שתפו בטלגרם',
    rights_reserved: 'כל הזכויות שמורות ל- Chipper © year',
    show_more: 'הצג עוד',
    home: 'ראשי',
    categories: 'קופונים לפי קטגוריה',
    in: 'מתוך',
    days: ' ימים',
    day: ' יום',
    no_branches: 'כל סניפי הרשת',
    help: 'שאלות ותשובות',
    menu_help: 'שאלות ותשובות',
    account: 'החשבון שלי',
    notification_title: 'ההתראות שלי',
    saved_coupons: 'קופונים ששמרת  ',
    clear_all_coupons: 'הסר הכל',
    settings: 'הגדרות',
    error_gender: 'מגדר נדרש!',
    error_first_name: ' שם פרטי הינו שדה חובה',
    error_last_name: 'שם משפחה הינו שדה חובה',
    error_phone: 'מספר טלפון הינו שדה חובה',
    error_phone_match: 'מספר הטלפון שהוכנס שגוי',
    error_city: 'עיר הינו שדה חובה',
    city_required: 'עיר הינו שדה חובה',
    city_invalid: 'אנא הזן עיר',
    error_street: 'רחוב הינו שדה חובה',
    error_birth: '    תאריך לידה הינו שדה חובה',
    error_birth_match: 'תאריך הלידה שהוכנס  אינו תקין',
    login: 'ברוכים המצטרפים',
    registration: 'Complete your registration',
    sign_in_text: 'איך תרצה להתחבר ?',
    sign_up_text: 'Please enter your personal information to complete registration',
    sign_up_agree1_1:
      'I know that registration on the site is conditional on joining the Chipper customer club. I confirm that I’ve read the',
    sign_up_agree1_2: 'of the site, including the',
    sign_up_agree1_3: 'applicable to them.',
    sign_up_agree2:
      "אני מאשר/ת לקבל עדכונים, קופונים, הטבות, מבצעים ופרסומות לנייד, למייל או בכל ערוץ תקשורת אחר, מצ'יפר ו/או מחברות הקשורות עמה.",
    email: 'דואר אלקטרוני:',
    email_adr: 'כתובת דוא"ל:',
    can_we_help: 'איך אנחנו יכולים לעזור?',
    password: 'סיסמה:',
    or_login: 'התחברות קלה ומהירה יותר',
    create_account: 'צור חשבון',
    not_registered: 'לא רשום?',
    profile_menu_1: 'הפרופיל שלי',
    profile_menu_2: 'קטגוריות שנבחרו',
    profile_menu_3: 'קופונים ששמרת',
    profile_menu_4: 'הגדרות',
    profile_menu_5: 'התנתקות',
    profile_menu_6: 'Payment & Transactions',
    clear_all: 'מחיקת כל ההתראות',
    list_coupons: 'רשימת ההנחות שהוספת לרשימת השמורים:',
    no_coupons: 'כרגע אין קופונים בקטגוריה הזאת',
    account_payments: 'Check your payments list',
    account_settings: 'עדכון פרטים',
    change_settings: 'עדכן את הפרטים שלך ',
    change_avatar: 'להחליף תמונה',
    required_avatar: ' התמונה הכי יפה שלך, בפורמט GIF, JPG או PNG. נדרש 300x300',
    first_name: 'שם פרטי',
    last_name: 'שם משפחה',
    phone: 'טלפון:',
    phone_number: 'מספר טלפון',
    city: 'עיר',
    street: 'רחוב',
    male: 'זכר',
    error_file: 'הקובץ גדול מדי. גודלו לא יעלה על 5MB.',
    female: 'נקבה',
    date_of_birth: 'תאריך לידה',
    postal_code: 'מיקוד',
    save_changes: 'אוקי, אפשר לשמור',
    has_been_added: 'הוספנו את הקופון לרשימת השמורים שלך',
    confirm_delete_title: 'אשר את מחיקת רשימת השמורים',
    confirm_delete_question: 'האם ברצונך להסיר את כל הקופונים מרשימת השמורים שלך?',
    cancel: 'בטל',
    cancelation: 'ביטול',
    select: 'בחר',
    delete: 'מחק',
    favourite_categories: 'קטגוריות שבחרת',
    favourite_categories_list: 'מהקטגוריות המועדפות עלייך',
    new: 'חדש',
    amount: 'הכי פופולרי',
    about_to: 'עומד להסתיים',
    chipper_me: "צ'פר אותי",
    top_categories: 'קטגוריות מובילות',
    view_all: 'כל ההתראות',
    view_all_cat: 'כל הקטגוריות',
    were_selected: 'קטגוריות מועדפות נבחרו',
    result_found: 'מצאנו total תוצאות',
    search_term: 'תוצאות חיפוש עבור:',
    change_categories: 'בחירת קטגוריות',
    your_list: 'בחרת *value קטגוריות',
    no_categories_1: 'כדי שנוכל להציג לך את הקופונים שבאמת יחסכו לך, עליך לבחור',
    no_categories_2: 'את הקטגוריות שמעניינות אותך',
    reset_password: 'לאפס את הסיסמה',
    question_reset_password: 'איך אתה רוצה לאפס את הסיסמה שלך?',
    via_sms: 'התחברות עם SMS',
    get_sms: 'קבל SMS',
    get_email: 'קבל אימייל',
    receive_text: 'קבל טקסט',
    receive_sms: 'קבל סמס',
    via_email: 'התחברות עם מייל',
    receive_email: 'קבל אימייל',
    security_code: 'הזן קוד אבטחה',
    resend: 'שלח שוב',
    reset_my_password: 'אפס את הסיסמה שלי',
    create_password: 'צור סיסמה',
    continue: 'שלחו לי קוד כניסה',
    enter_phone: 'לאיזה מספר לשלוח את ה- SMS?',
    choose_file_to_attach: 'צרף קובץ',
    enter_email: 'לאיזה מייל לשלוח ?',
    your_code: 'הקלד את הקוד שלך',
    security_code_example: 'שלחנו למייל שלך קוד אבטחה בן 6 ספרות ',
    another_way: 'נסה דרך אחרת',
    unique_password: 'בחר סיסמה שקשה לנחש וייחודי לחשבון זה',
    all_set: 'אתה מוכן',
    can_login: 'עכשיו אתה יכול להתחבר באמצעות הסיסמה החדשה שלך.',
    lost_password: 'שכחת את הסיסמה?',
    your_email: 'האימייל שלך',
    your_phone: 'הטלפון שלך',
    new_pass: 'סיסמה חדשה',
    digits_6: 'הקוד צריך לכלול 6 ספרות',
    pass_required: 'לא הוזנה סיסמה.',
    pass_short: 'הסיסמה קצרה מדי -  נא הזן סיסמה בת  8 תווים מינימום.',
    pass_contain: 'הסיסמה יכולה להכיל אותיות ומספרים לטיניים בלבד.',
    about: 'אודות',
    alerts: 'התראות',
    cant_see: 'אנחנו לא מצליחים למצוא את העמוד שאתה מחפש',
    attach_files_req: 'יש צורך לצרף קבצים',
    comments: 'תגובות',
    contact_soon: 'אנחנו ניצור עמך קשר בקרוב',
    customer_support: 'עבור לקוחות',
    discount_avg: 'הנחה ממוצעת',
    attach_files: 'צרף קבצים',
    download_app: 'יש לנו גם אפליקציה',
    download_on: 'הורדה מ',
    email_address: 'אימייל',
    enter_number: 'הזן את מספר הטלפון',
    error_company_name: 'נא הזן פרטי חברה',
    error_email: 'נא הזן אימייל',
    error_full_name: 'נא הזן שם מלא',
    full_name: 'שם מלא:',
    has_been_removed: 'הסרנו את הקופון מרשימת השמורים שלך',
    help_page: 'עזרה ותמיכה',
    help_text: 'נא בקר במרכז העזרה שלנו',
    help_you: 'רק טוב יצא מזה',
    home_page: 'דף הבית',
    home_text: 'חזור לדף הבית',
    honey: "מה חברי צ'יפר מקבלים",
    in_touch: 'לא מצאת תשובה',
    get_in_touch: 'נהיה בקשר',
    love_to_help_you: 'נשמח לדבר על איך אנחנו יכולים לעזור לך.',
    main_store: 'כתובת הסניף המרכזית',
    merchants: 'ספקים אשר משתתפים',
    message: 'רוצה להרחיב',
    ok: ' אישור',
    or: 'או',
    our_coupons: 'הקופונים שלנו',
    phone_numbers: 'מספר טלפון',
    profile: 'פרופיל',
    register: 'מצטרפים בחינם ומתחילים למכור',
    register_coupon: 'שווה להיות פרטנר',
    savings: 'חסכון ממוצע שנתי',
    search_page: 'חפש',
    search_text: 'חפש עם חיפוש מתקדם',
    send_code: 'כניסה',
    sent_sms: 'נשלח SMS  עם קוד לטלפון שלך',
    sent_mail: 'מכתב עם קוד לדוא"ל שלך',
    sent_success: 'בקשתך נשלחה בהצלחה',
    sign_in: 'התחבר',
    sign_up: 'הירשם',
    subject: 'נושא הפנייה',
    submit: 'שליחה',
    tech_support: 'תמיכה טכנית',
    thanks: 'תודה רבה',
    to_continue: 'להמשך',
    verification: 'אימות',
    wishlist: 'רשימת שמורים',
    confirm_age: 'רגע, רק מוודאים',
    must_be_18: 'הקופון הזה מוגבל לשימוש מגיל 18 ומעלה',
    over_18: 'מעולה, כבר חגגתי',
    category_removed: 'הקטגוריה הוסרה מרשימת העדפות',
    category_added: 'הקטגוריה נוספה לרשימת העדפות',
    profile_avatar_error: 'התמונה אינה בגודל המתאים',
    list_view: 'הצגה ברשימה',
    map_view: 'הצגה על מפה',
    details: 'פרטים',
    results: 'קופונים',
    address: 'כתובת',
    error_address: 'כתובת הינו שדה חובה',
    gender: 'מין',
    set_address: 'נא הזן כתובת',
    no_address_text: 'כתובתך אינה זמינה, נא הזן כתובת תחת תפריט  ההגדרות',
    no_address: 'כתובתך  אינה זמינה',
    add_to_saved_coupons: 'הוסף לרשימת השמורים',
    remove_from_saved_coupons: 'הסרה מהרשימה',
    please_display_QR: 'הציגו את קוד ה QR בקופה כדי להנות מההנחה',
    use_a_coupon_number: 'ואפשר גם להראות את הקוד הזה:',
    categories_selected_1: 'בחרת עד עכשיו ',
    categories_selected_2: ' קטגוריות',
    no_categories_selected: 'עוד לא בחרת קטגוריות כדי לראות קופונים שבאמת מעניינים אותך. שווה לבחור כמה',
    error_message: 'נא הזן את הודעתך ',
    error_subject: 'נושא חובה!',
    filter_by: 'סינון לפי:',
    no_coupons_title: 'כרגע אין קופונים לשותף זה',
    no_coupons_title_result: '🙂 אין לנו קופונים למוצר הזה, בואו ננסה מוצר אחר',
    no_coupons_desc: 'שנבדוק מה קורה אצל',
    no_coupons_desc_other: ' שותפים אחרים?',
    no_coupons_desc_result: "אפשר לצ'יפר אותך במוצר אחר?",
    cancel_18: 'אוקיי, אחזור אז',
    no_coupons_saved_1: 'עדיין לא שמרת קופונים',
    no_coupons_saved_2: 'וזה אומר שעוד לא חסכת איתנו. חבל, זה שווה כל שקל  :) ',
    no_coupons_saved_3: 'לחיצה על אייקון הלב תשמור לך קופונים למימוש. ',
    error_auto_complete: 'על איזו כתובת חשבת?',
    no_coupons_field: 'לא מצאנו קופונים בסביבה שלך. מומלץ להגדיל את טווח החיפוש',
    spec_deals: 'דילים מיוחדים',
    back: 'חזור',
    Su: 'א',
    Mo: 'ב',
    Tu: 'ג',
    We: 'ד',
    Th: 'ה',
    Fr: 'ו',
    Sa: 'ז',
    resend_pass: 'לא קיבלתי - שלחו שוב',
    notifications: 'ההתראות שלי',
    no_notifications: 'כרגע, אין התראות',
    helpSubjectPlaceholder: 'בחר נושא פניה',
    notification_name_days: 'couponName couponNumber יפוג בעוד daysEx ימים',
    notification_name_day: 'couponName couponNumber יפוג בעוד daysEx ימים',
    notification_name_today: 'couponName couponNumber יפוג היום',
    created_at: ' לפני day ימים ',
    today: 'היום',
    back_button: 'בטל',
    my_acc: 'חשבון שלי',
    without_limitation: 'ללא הגבלה',
    once_an_hour: 'כל שעה ',
    once_a_day: 'כל יום',
    once_a_week: 'כל שבוע ',
    once_a_month: 'Once a month',
    email_notification: 'התראות עבור דוא"ל',
    sms_notification: 'התראות עבור סמס',
    done: 'סגור',
    code_pos: 'נא הצג את הקוד לקופאי/ת',
    error_email_match: 'כתובת המייל אינה תקינה ',
    member_no: 'מס׳ חבר',
    limited_time: 'זמן',
    limited_time_desc: 'זמן מוגבל',
    weak_alcohol_text: 'מכיל אלכוהול, מומלץ להימנע משתייה מופרזת!',
    strong_alcohol_text: 'צריכה מופרזת של אלכוהול. מסכנת חיים ומזיקה לבריאות!',
    agree1:
      'ידוע לי ששהרשמה באתר מותנית בהצטרפות מועדון לקוחות chipper אני מאשר/ת שקראתי את *terms* האתר לרבות מדיניות הפרטיות החלה לגביהם.',
    agree2: `אני מאשר/ת לקבל עדכונים, קופונים, הטבות, מבצעים ופרסומות לנייד, למייל או בכל ערוץ תקשורת אחר, מצ'יפר ו/או מחברות הקשורות עמה`,
    agree3: ' האתר לרבות מדיניות הפרטיות החלה לגביהם.',
    agree4:
      "אני מאשר/ת לקבל עדכונים, קופונים, הטבות, מבצעים ופרסומות לנייד, למייל או בכל ערוץ תקשורת אחר, מצ'יפר ו/או מחברות הקשורות עמה.",
    required: 'נא אשר את תנאי השימוש',
    agreements_are_required: 'אישור עבור תנאי שימוש חסר',
    approve_agreements: 'נא אשר את תנאי השימוש תחת הפרופיל',
    to_profile: 'לדף הפרופיל',
    faq: 'שאלות נפוצות',
    open_faq: 'פתח שאלות נפוצות',
    help_text_file: 'אתה יכול להוריד סוגי קבצים',
    max_size_file: 'קובץ בגודל מקסימלי',
    deleting_status: 'סטטוס מחיקה',
    km: 'ק"מ',
    wrong_code: 'קוד שגוי, נסה שוב!',
    wrong_phone: 'מספר הטלפון שהזנת אינו תקין, נא הזן שנית',
    saved_profile: 'השינויים נשמרו בהצלחה',
    valid_phone: 'נא הזן מספר טלפון, ללא הספרה 0',
    saved_modal: "ברוכים המצטרפים לצ'יפר",
    saved_modal_button: 'אישור',
    labelRangeKm: "ק'מ",
    priceBeforeDiscount: 'מחיר ליחידה לפני ההנחה:',
    savedProfile: 'הפרטים נשמרו בהצלחה',
    ending_today: 'מסתיים היום',
    image_for_illustration_only: 'התמונה להמחשה בלבד',
    coupon_for_register_company: 'שם העסק שמציע את הקופון:',
    transactions_history: 'TRANSACTIONS HISTORY',
    transactions: 'TRANSACTIONS',
    payment_methods: 'PAYMENT METHODS',
    show_all: 'הצג הכול',
    show_more: 'הצג עוד',
    hot_deals: 'דילים חמים',
    poplar_categories: 'קטגוריות פופולריות',
    how_it_works: `איך צ'יפר עובד?`,
    use_desktop_or_mobile: `השתמש במחשב או באפליקציה`,
    setup_an_account: `צור חשבון חדש`,
    explore_the_products: `חפש את הקופונים המתאימים עבורך`,
    use_chipper_number_to_get_discount: `השתמש במספר צ'יפר כדי לקבל את ההנחה`,
    news: 'דילים חדשים',
    coupons_about_to_end: 'קופונים שעומדים להסתיים',
    offers: 'הצעות',
    shop_by_providers: `השותפים שלנו`,
    coupons: 'קופונים',
    main_menu: 'תפריט ראשי',
    all_categories: 'כל הקטגוריות',
    discount: 'הנחה',
    show_all_coupons: 'הצג את כל הקופונים',
    popular_coupons: 'קופונים פופולריים',
    on_date: 'בתאריך',
    pricePerUnit: 'מחיר ליחידה:',
    why_us_big_title: 'מהיום, משווקים קופונים ישירות לצרכן',
    why_us_big_title_rest: 'ללא מתווכים בדרך!',
    why_us: 'למה להצטרף אלינו?',
    why_us_title_1: 'DATA כלי חדשני לשיווק ממוקד מבוסס',
    why_us_title_2: `תהליך קל, מהיר ואפקטיבי להוזלת סל הקניות ללקוח והגדלת מכירות לספק`,
    why_us_title_3: 'איתנו כולם מרוויחים',
    why_us_answer_1_1: 'פילוח מדויק - לפי גילאים, מיקום ותחומי עניין',
    why_us_answer_1_2: 'פרסונליזציה- הצעה אישית של קופונים מתוך למידת הצרכן',
    why_us_answer_1_3: 'הפקת תובנות - דשבורדים, דוחות סטטיסטים, זיהוי לקוחות פוטנציאליים',
    why_us_answer_2_1: 'פתוח לכולם - כל עסק יכול להיות יצרן קופונים ',
    why_us_answer_2_2: 'אינטגרציית קופות מלאה ללא סריקות, ללא ברקודים, ללא עיכובים',
    why_us_answer_2_3: 'אינטגרציה פשוטה לאתרים שליטה מלאה בניהול יעיל ואפקטיבי בזמן אמת',
    why_us_answer_2_4: 'שליטה מלאה בניהול יעיל ואפקטיבי של מערך הקופונים ',
    why_us_answer_3_1: 'מצטרפים בחינם  בלי התחייבות בלי דמי הקמה',
    why_us_answer_3_2: 'משלמים רק במימוש לא מכרתם, לא שילמתם',
    why_us_answer_3_3: 'יכולים לגשת ללקוחות שלכם בקלות ובמהירות',
    remove_account_confirm: `משתמש יקר, <br> <br>
    אנו רוצים לוודא שאתה מודע לחלוטין להשלכות לפני שתמשיך עם מחיקת הפרופיל שלך.                                                      שים לב, מחיקת הפרופיל שלך היא פעולה בלתי הפיכה. לאחר שתאשר את המחיקה, כל הנתונים ופרטי הפרופיל שלך יימחקו לצמיתות מהמערכת שלנו.

    <br> <br>
    אם תבחר להירשם שוב בעתיד, תתחיל עם פרופיל חדש לחלוטין. פרופיל חדש זה לא יכיל נתונים, היסטוריה או הגדרות מהפרופיל שנמחק.

    <br> <br>
    אנו ממליצים לך לשקול החלטה זו היטב. אם יש לך חששות או שאלות, אל תהסס לפנות אלינו לפני שתמשיך.

    <br> <br>
    תודה על תשומת הלב לעניין חשוב זה, צ'יפר.
    `,
    deactivate_account: 'השבת את החשבון',
    are_you_sure: 'האם אתה בטוח?',
    publish_modal_text: 'פרסם את בית העסק שלך אצלנו',
    publish_modal_button: 'להצטרפות',
    online: 'מקוון',
    distance: 'מרחק',
    updateModalText: `אתה משתמש בגרסה לא מעודכנת,
אנא עדכן גרסה.`,
    update: 'עדכן',
    aboutUs: 'אודות',
  },
  ru: {
    'You can resize the picture': 'Вы можете изменить размер картинки',
    save: 'сохранить',
    big_file: 'Файл слишком большой! размер файла не должен превышать 5mb',
    results_total: 'найдено результатов',
    radius: 'Радиус',
    remove: 'remove',
    edit_card: 'edit card',
    cardHolder: 'Cardholder name',
    cardNumber: 'Card number',
    expires: 'Exp.date',
    cvv: 'CVV',
    save_address: 'Save this address for future purchases',
    such_phone_number_exist: 'Такой номер телефона существует',
    open_with: 'Открыть',
    by_online: 'Купить онлайн',
    by_in_store: 'Купить сейчас',
    quick_view: 'Быстрый просмотр',
    qr_app_title: 'Загрузите наше приложение',
    spec_details: 'Особые детали',
    error_file: 'Файл слишком большой. Его размер не может превышать 5 МБ.',
    qr_app_desc:
      'Chipper сохраняет множество скидочных купонов на тысячи товаров. Просто скачайте, осознайте и сохраните.',
    nearby_additional_text: 'Вокруг вас много горячих купонов. Просто введите адрес и мы найдем их для вас',
    get_you_app: 'Скачайте наше приложение',
    image_for_illustration_only: 'Изображение только для иллюстрации',
    attach_files: 'Прикрепить файлы',
    choose_file_to_attach: 'Выберите файл для прикрепления',
    description:
      'Мы хотим оптимизировать процесс настройки, чтобы ваша команда могла сэкономить время ' +
      'при создании веб-сайтов. Никогда не пропустите более низкую цену на Lorem Ipsum -' +
      ' мы сравним каждого продавца, чтобы найти для вас самое выгодное предложение. ' +
      'И да, это работает даже с Чиппером! Вы приметили что-то конкретное? Добавьте его в ' +
      'свой список, и мы сообщим вам, когда цена упадет. Все еще ищете промокоды? Мы мгновенно найдем и применим лучшие интернет-коды прямо к вашей корзине ',
    add_to_saved_coupons: 'Добавить в список сохраненных купонов',
    coupon_for_register_company: 'Название компании, предлагающей купон:',
    menu_1: 'Горячие предложения',
    menu_2: 'Категории',
    menu_3: 'Поблизости',
    menu_4: 'Обслуживание клиентов',
    menu_5: 'Частная территория',
    menu_6: 'Предпочитаемые категории',
    menu_7: 'Настройки',
    menu_8: 'Больше о Chipper',
    menu_9: 'Зарегистрироваться как компания',
    describe_register_company:
      'Chipper передает власть и управление в ваши руки производителям, оптовикам и владельцам бизнеса. С сегодняшнего дня вы можете самостоятельно производить купоны Chipper, проводить кампании, напрямую связываться с конечными потребителями и увеличивать продажи простым и удобным способом. <br /> <br />Присоединяйтесь бесплатно, без обязательств и без платы за установку, и платите комиссию только тогда, когда ваши купоны Chipper будут погашены. Как ни крути, вы победили. Вы выбираете продукты и скидку, а мы даем вам новую платформу и способ продажи вашим клиентам и новой аудитории по местоположению и категориям. <br /> <br />И индивидуально.',
    menu_10: 'Помощь',
    menu_11: 'Связаться с нами',
    menu_12: 'Предпочитаемые категории',
    menu_13: 'Купоны для меня',
    menu_14: 'Центр помощи',
    menu_15: 'Наше приложение',
    menu_16: 'Системные сообщения',
    offers_according:
      'Системные сообщения, такие как: сброс пароля, Напоминания об упражнениях, обратная связь со службой поддержки',
    notifications_according:
      'System notifications such as: password reset,<br />realization reminders, customer support feedback.',
    title_about:
      "With we want to optimize the customization process so your team can save time when building websites. Never miss a lower price on Lorem Ipsum – we’ll compare every seller to find you the best possible deal. And yes, it even works with Chipper! <br /> </br> Have your eye on something specific? Add it to your list and we’ll notify you when the price drops. Still searching for promo codes? We'll instantly find and apply the Internet's best codes directly to your cart.",
    companies: 'Опубликовано нами',
    search: 'Поиск...',
    search_transaction: 'Search transaction',
    prev_page: 'Вернуться на предыдущую страницу',
    company_name: 'Название компании:',
    coupon_number: 'Номер купона',
    realization_in_store: 'Реализация в магазине',
    realization_online: 'РЕАЛИЗАЦИЯ ОНЛАЙН',
    realized: 'Реализованные',
    deals_end: 'Сделка заканчивается',
    time: 'Время',
    branches: 'Филиалы',
    fine_print: 'Мелкий шрифт',
    terms_of_use: 'Условия эксплуатации',
    following_branches: 'Купон можно погасить в следующих филиалах',
    products_title: 'Товары, участвующие в купоне',
    sku: 'Артикул продукта:',
    interesting_deals: 'больше предложений, которые могут вас заинтересовать',
    discount: 'Скидка',
    realized_card: 'осуществленный',
    realized_card_one: 'осуществленный',
    days_left: 'num1 осталось дней',
    day_left: 'num1 день остался',
    look_online: 'Ищите нас в Интернете',
    title_menu_1: 'Главный',
    title_menu_2: 'Частная территория',
    title_menu_3: 'Общий',
    privacy_policy: 'Политика конфиденциальности',
    accessibility_statement: 'Accessibility Statement',
    menu_help: 'Вопросы и ответы',
    terms: 'Условия эксплуатации',
    rights_reserved: 'Все права принадлежат Chipper © year',
    show_more: 'Показать больше',
    home: 'Дом',
    categories: 'Категории',
    in: 'B',
    days: ' дней',
    day: ' день',
    no_branches: 'Все филиалы',
    help: 'Вопросы и ответы',
    account: 'Мой аккаунт',
    notification_title: 'уведомления',
    my_acc: 'Счет',
    saved_coupons: 'Cохраненные купоны',
    clear_all_coupons: 'Очистить все купоны',
    settings: 'Настройки',
    share_on_facebook: 'Поделиться через Facebook',
    share_on_whatsApp: 'Поделиться в WhatsApp',
    share_on_telegram: 'Поделиться в Telegram',
    account_payments: 'Check your payments list',
    transactions_history: 'TRANSACTIONS HISTORY',
    payment_methods: 'PAYMENT METHODS',
    error_first_name: 'Имя обязательно',
    error_full_name: 'Требуется полное имя',
    error_company_name: 'Требуется полная компания',
    error_last_name: 'Фамилия обязательна',
    error_phone: 'Телефон обязателен',
    error_phone_match: 'Телефон неверный',
    error_city: 'Требуется город',
    city_required: 'Поле “Город” обязательно',
    city_invalid: 'Пожалуйста, введите город',
    error_street: 'Требуется улица',
    error_birth: 'Дата рождения не может быть больше сегодняшней',
    error_birth_match: 'Пожалуйста, введите правильную дату',
    login: 'Авторизоваться',
    registration: 'Complete your registration',
    sign_in_text: 'How do you want to sign in?',
    sign_up_text: 'Please enter your personal information to complete registration',
    sign_up_agree1_1:
      'I know that registration on the site is conditional on joining the Chipper customer club. I confirm that I’ve read the',
    sign_up_agree1_2: 'of the site, including the',
    sign_up_agree1_3: 'applicable to them.',
    sign_up_agree2:
      'Я согласен получать обновления, купоны, преимущества, рекламные акции и рекламу для мобильных устройств, электронной почты или любого другого канала связи, Chipper и / или компаний, связанных с ним.',
    email: 'Адрес электронной почты:',
    email_adr: 'Адрес электронной почты:',
    can_we_help: 'Как мы можем помочь?',
    alerts: 'предупреждения',
    sign_in: 'Войти',
    sign_up: 'Зарегестрироваться ',
    to_continue: 'Продолжить',
    wishlist: 'Избранное',
    or: 'или',
    access_geo_location: 'Доступ к геолокации',
    access_geo_location_prompt: 'Пожалуйста, разрешите доступ для определения местоположения.',
    access_geo_location_denied:
      'Ваш доступ к геолокации заблокирован, пожалуйста, включите его на устройстве или в браузер для получения точной информации рядом с вами.',
    password: 'Пароль:',
    or_login: 'Или войдите с помощью',
    create_account: 'Завести аккаунтt',
    not_registered: 'Не зарегистрирован?',
    profile_menu_1: 'Мой профиль',
    profile: 'Профиль',
    profile_menu_2: 'Предпочитаемые категории',
    profile_menu_3: 'Cохраненные купоны',
    profile_menu_4: 'Настройки',
    profile_menu_5: 'Выйти',
    profile_menu_6: 'Payment & Transactions',
    clear_all: 'Cчистить все',
    list_coupons: 'Список купонов, которые вы добавили в список желаний:',
    no_coupons: 'Нет купонов',
    account_settings: 'Настройки аккаунта',
    change_settings: 'Измените настройки своей учетной записи',
    change_avatar: 'Сменить аватар',
    required_avatar: 'Загрузите изображение в формате JPG, GIF или PNG. (требуется 300 x 300 px)  ',
    first_name: 'Имя',
    last_name: 'Фамилия',
    message: 'Сообщение',
    submit: 'Отправить',
    subject: 'Тема',
    phone: 'Телефон:',
    phone_number: 'Номер телефона:',
    city: 'Город',
    street: 'улица',
    male: 'Мужчина',
    female: 'Женщина',
    date_of_birth: 'Дата рождения',
    postal_code: 'Почтовый Индекс',
    save_changes: 'Сохранить изменения',
    has_been_added: 'Купон добавлен к сохраненным купонам',
    has_been_removed: 'Купон удален из сохраненных купонов',
    confirm_delete_title: 'Confirm delete wishlist',
    confirm_delete_question: 'Вы хотите удалить все купоны из списка желаний?',
    cancel: 'Отмена',
    cancelation: 'Отмена',
    select: 'Выбрать',
    delete: 'Удалить',
    favourite_categories: 'Любимые категории',
    favourite_categories_list: 'любимые категории',
    new: 'Новый',
    amount: 'Количество реализаций',
    about_to: 'Вот-вот закончится',
    chipper_me: 'Chipper меня',
    top_categories: 'Топ категории',
    view_all: 'Посмотреть все',
    were_selected: 'Выбранные любимые категории',
    result_found: 'Всего найдено результатов total',
    search_term: 'Результат поиска для:',
    change_categories: 'Изменить категории',
    your_list: 'Вы выбрали *value категорию из ваших',
    no_categories_1: 'Для того, чтобы мы показали вам купоны, которые действительно спасут вас, вам нужно выбрать',
    no_categories_2: 'Категории, которые вас интересуют',
    reset_password: 'Сброс пароля',
    question_reset_password: 'Как вы хотите сбросить пароль?',
    via_sms: 'через смс:',
    get_sms: 'Получить SMS',
    get_email: 'Получить по электронной почте',
    receive_text: 'Получите текст',
    receive_sms: 'Receive a SMS',
    via_email: 'Войти по электронной почте:',
    receive_email: 'Получить по электронной почте',
    security_code: 'Введите код безопасности',
    resend: 'Отправь еще раз',
    reset_my_password: 'Сбросить пароль',
    create_password: 'Создайте пароль',
    continue: 'Продолжать',
    send_code: 'Отправить код',
    savings: 'Средняя годовая экономия',
    merchants: 'Участвующие продавцы',
    discount_avg: 'Средняя скидка',
    honey: 'Что получают участники Chipper:',
    sent_sms: 'Мы отправили на ваш телефон СМС с кодом активации',
    sent_mail: 'Мы отправили код активации на вашу электронную почту',
    verification: 'Проверка',
    enter_phone: 'Введите свой телефон',
    enter_email: 'Пожалуйста, введите Ваш адрес электронной почты',
    your_code: 'Введите ваш код',
    security_code_example: 'Мы отправили на ваш адрес электронной почты специальный 6-значный код безопасности',
    another_way: 'Попробуй другой способ',
    unique_password: 'Выберите пароль, который сложно угадать и уникальный для этой учетной записи',
    all_set: 'Все готово',
    can_login: 'Теперь вы можете войти в систему, используя свой новый пароль',
    lost_password: 'Забыли Ваш пароль?',
    your_email: 'Ваш электронный адрес',
    your_phone: 'Твой номер телефона',
    new_pass: 'новый пароль',
    digits_6: 'Код должен состоять из 6 цифр',
    pass_required: 'Пароль не был предоставлен',
    pass_short: 'Пароль слишком короткий - не менее 8 символов',
    pass_contain: 'Пароль может содержать только буквы и цифры',
    help_text_file: 'Вы можете скачать типы файлов',
    max_size_file: 'Максимальный размер файла',
    register: 'регистр',
    full_name: 'Полное имя:',
    comments: 'Комментарии',
    thanks: 'Спасибо',
    sent_success: 'Ваш запрос был успешно отправлен',
    contact_soon: 'Мы скоро с Вами свяжемся',
    ok: 'хорошо',
    register_coupon: 'Зарегистрируйтесь как производитель купонов',
    in_touch: 'Не нашли ответа?',
    get_in_touch: 'Связаться',
    love_to_help_you: 'Мы хотели бы поговорить о том, как мы можем вам помочь.',
    help_you: 'Свяжитесь с нами и мы свяжемся с вами в ближайшее время',
    email_address: 'Адрес электронной почты',
    phone_numbers: 'Номер телефона',
    main_store: 'Адрес основного магазина',
    tech_support: 'Техническая поддержка: ',
    customer_support: 'Заказчикам: ',
    home_page: 'Домашняя страница',
    home_text: 'Вернуться на главную страницу',
    search_page: 'Поиск',
    search_text: 'Найти с расширенным поиском',
    help_page: 'Помощь и поддержка',
    help_text: 'Посетите наш справочный центр',
    cant_see: 'Кажется, мы не можем найти нужную страницу',
    download_app: 'Скачайте наше приложение',
    download_on: 'Скачать на',
    about: 'O',
    enter_number: 'Введите свой номер телефона',
    our_coupons: 'Наши купоны',
    confirm_age: 'Подтвердите свой возраст',
    must_be_18: 'Чтобы использовать этот купон, вам должно быть не менее 18 лет',
    over_18: 'Я старше 18',
    error_email: 'Электронная почта обязательна',
    category_removed: 'Категория была удалена из вашего списка',
    category_added: 'Категория добавлена ​​в ваш список',
    attach_files_req: 'Прикрепить файлы обязательно',
    profile_avatar_error: 'Изображение неправильного разрешения.',
    list_view: 'Перейти к просмотру списка',
    map_view: 'Перейти к просмотру карты',
    details: 'Подробности',
    results: 'Результаты',
    address: 'Адрес',
    error_address: 'Адрес обязателен',
    gender: 'Пол',
    you_are_not_logged_in: 'Вы не авторизованы.',
    access_denied: 'В доступе отказано.',
    not_found: 'Не обнаружена.',
    something_went_wrong: 'Упс! Что-то пошло не так.',
    no_address: 'Ваш адрес недоступен',
    no_address_text: 'Ваш адрес недоступен, введите адрес в меню настроек',
    set_address: 'Установить адрес',
    remove_from_saved_coupons: 'Удалить из списка сохраненных купонов',
    please_display_QR: 'Пожалуйста, покажите QR-код провайдеру',
    use_a_coupon_number: 'Или используйте номер купона',
    categories_selected_1: 'Вы выбрали ',
    categories_selected_2: ' Категории',
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
    no_categories_selected: 'Вы еще не выбрали категории, чтобы увидеть интересующие вас купоны',
    error_message: 'Пожалуйста, введите ваше сообщение',
    error_subject: 'Пожалуйста, введите вашу тему',
    filter_by: 'Фильтровать по',
    no_coupons_title_result: 'У нас нет купонов на этот продукт, давайте попробуем другой. 🙂',
    no_coupons_desc_result: 'Могу ли я добавить вам другой продукт?',
    no_coupons_title: 'В настоящее время у этой компании нет доступных купонов',
    no_coupons_desc: 'Посмотрим, что происходит в',
    no_coupons_desc_other: 'другой компании?',
    cancel_18: 'отмена',
    no_coupons_saved_1: 'Увы, вы еще не сохранили купоны',
    no_coupons_saved_2: 'А это значит, что вы еще не сэкономили у нас. Жаль это того стоит:)',
    no_coupons_saved_3: 'Нажав на значок сердца, вы сохраните купон',
    error_auto_complete: 'Какой адрес вы обдумывали?',
    no_coupons_field: 'Мы не нашли купонов в вашем регионе. Рекомендуется увеличить диапазон поиска',
    spec_deals: 'Специальные предложения',
    back: 'Назад',
    notifications: 'Уведомления',
    resend_pass: 'Отправить код еще раз',
    no_notifications: 'В настоящее время нет уведомлений',
    helpSubjectPlaceholder: 'Выберите тему',
    error_gender: 'Пол должен быть обязателен!',
    notification_name_days: 'couponName couponNumber Истекает через daysEx дней',
    notification_name_day: 'couponName couponNumber Истекает через daysEx день',
    notification_name_today: 'couponName couponNumber истекает сегодня',
    created_at: 'день days назад',
    today: 'Сегодня',
    back_button: 'Назад',
    Su: 'Вс',
    Mo: 'Пн',
    Tu: 'Вт',
    We: 'Ср',
    Th: 'Чт',
    Fr: 'Пт',
    Sa: 'Сб',
    without_limitation: 'неограниченный',
    once_an_hour: 'Раз в час',
    once_a_day: 'Один раз в день',
    once_a_week: 'Раз в неделю',
    once_a_month: 'Раз в месяц',
    email_notification: 'Уведомления по электронной почте',
    sms_notification: 'SMS-уведомления',
    error_email_match: 'Электронная почта должна быть действительной',
    member_no: 'мой номер',
    done: 'сделано',
    code_pos: 'Пожалуйста, покажите этот код провайдеру',
    limited_time: 'Время',
    limited_time_desc: 'Ограниченный срок',
    agree1:
      'Я знаю, что регистрация на сайте возможна только при вступлении в клуб покупателей. Я подтверждаю, что ознакомился с *terms* сайта, включая применимую к ним политику конфиденциальности',
    agree2:
      'Я согласен получать обновления, купоны, преимущества, рекламные акции и рекламу для мобильных устройств, электронной почты или любого другого канала связи, Chipper и / или компаний, связанных с ним',
    required: 'Данное поле является обязательным к заполнению',
    weak_alcohol_text: 'Содержит алкоголь, рекомендуется избегать чрезмерного употребления алкоголя',
    strong_alcohol_text: 'Чрезмерное употребление алкоголя. Опасно для жизни и вредно для здоровья',

    agreements_are_required: 'Условия использования обязательны',
    approve_agreements: 'пожалуйста, подтвердите условия использования',
    to_profile: 'Перейти к профилю',
    faq: 'Часто задаваемые вопросы',
    open_faq: 'Открыть часто задаваемые вопросы',
    deleting_status: 'Удаление статуса',
    km: 'km',
    wrong_code: 'Пожалуйста, введите действительный код',
    wrong_phone: 'Пожалуйста, введите действующий телефонный номер',
    saved_profile: 'Профиль сохранен',
    valid_phone: 'Телефон следует вводить без 0',
    saved_modal: 'Добро пожаловать в Chipper',
    saved_modal_button: 'хорошо',
    labelRangeKm: 'km',
    priceBeforeDiscount: 'Цена за единицу до скидки:',
    savedProfile: 'Ваша информация была сохранена',
    ending_today: 'Заканчивается сегодня',
    transactions: 'TRANSACTIONS',
    payment_methods: 'СПОСОБЫ ОПЛАТЫ',
    show_all: 'Показать всё',
    show_more: 'Показать больше',
    hot_deals: 'Горячие предложения',
    poplar_categories: 'Популярные категории',
    how_it_works: 'Как это работает ?',
    use_desktop_or_mobile: 'Используйте настольное или мобильное приложение',
    setup_an_account: 'Настройка учетной записи',
    explore_the_products: 'Изучите продукты',
    use_chipper_number_to_get_discount: 'Используйте номер Chipper, чтобы получить скидку',
    news: 'Новости',
    coupons_about_to_end: 'Купоны которые скоро закончатся',
    offers: 'Предложения',
    shop_by_providers: 'Наши партнёры',
    coupons: 'купоны',
    main_menu: 'Главное меню',
    all_categories: 'Все категории',
    discount: 'Скидка',
    show_all_coupons: 'Показать все купоны',
    popular_coupons: 'Популярные купоны',
    on_date: 'в дату',
    pricePerUnit: 'Цена за единицу:',
    deactivate_account: 'Деактивировать аккаунт',
    why_us_big_title: 'Starting today, coupons will be distributed directly to the consumer',
    why_us_big_title_rest: 'Without intermediates!',
    why_us: 'Зачем присоединяться к нам?',
    why_us_title_1: 'Инновационный инструмент таргетированного маркетинга на основе DATA',
    why_us_title_2: `Простой, быстрый и эффективный процесс cнижения стоимости корзины покупок для клиента и увеличения продаж для поставщика.`,
    why_us_title_3: 'С нами выгодно каждому!',
    why_us_answer_1_1: 'Точная сегментация – по возрасту, местоположению и интересам',
    why_us_answer_1_2: 'Персонализация — персональное предложение купонов на основе изучение потребителей',
    why_us_answer_1_3:
      'Генерация аналитики - информационные панели, статистические отчеты, выявление потенциальных клиентов',
    why_us_answer_2_1: 'Открыто для всех — любой бизнес может стать производителем купонов',
    why_us_answer_2_2: 'Полная интеграция кассы без сканирования, без штрих-кодов, без задержек',
    why_us_answer_2_3:
      'Простая интеграция с веб-сайтами, полный контроль для эффективного и результативного управления в режиме реального времени.',
    why_us_answer_2_4: 'Полный контроль над эффективным и результативным управлением множество купонов.',
    why_us_answer_3_1: 'Присоединяйтесь бесплатно без каких-либо обязательств и платы за установку.',
    why_us_answer_3_2: 'Вы платите только по факту реализации, не продали, не оплатили.',
    why_us_answer_3_3: 'Доступность позволяет легко и быстро получить доступ к своим клиентам.',
    remove_account_confirm: `Дорогой пользователь, <br> <br>

    Мы хотим убедиться, что вы полностью осведомлены о последствиях, прежде чем приступить к удалению своего профиля. Обратите внимание, что удаление вашего профиля является необратимым действием. Как только вы подтвердите удаление, все ваши данные и информация профиля будут навсегда удалены из нашей системы. <br> <br>
    
    Если вы решите зарегистрироваться снова в будущем, вы начнете с совершенно нового профиля. Этот новый профиль не будет содержать никаких данных, истории или настроек из вашего удаленного профиля. <br> <br>
    
    Мы рекомендуем вам тщательно обдумать это решение. Если у вас есть какие-либо сомнения или вопросы, пожалуйста, свяжитесь с нами, прежде чем продолжить. <br> <br>
    
    Спасибо за внимание к этой важной теме, Чиппер.`,
    are_you_sure: 'Вы уверены?',
    publish_modal_text: 'Рекламируйте свой бизнес с нами',
    publish_modal_button: 'Присоединиться',
    online: 'Онлайн',
    distance: 'Расстояние',
    updateModalText: `Вы используете устаревшую версию, пожалуйста обновите её.`,
    update: 'Обновить',
    aboutUs: 'O',
  },
};

export const LANG_NAMES = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'he',
    name: 'עברית',
  },
  {
    locale: 'ru',
    name: 'Русский',
  },
];
