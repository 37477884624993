import { useState, useEffect } from 'react';
import './FloatingButton.scss';
import userWay from '../assets/images/userWay.svg';

function FloatingButton() {
  const [position, setPosition] = useState(() => {
    if (window.innerWidth <= 767) {
      return { x: window.innerWidth - 50 - 10, y: window.innerHeight - 50 - 80 };
    }
    return { x: window.innerWidth - 80 - 60, y: window.innerHeight - 80 - 100 };
  });

  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [wasDragging, setWasDragging] = useState(false);

  const getButtonSize = () => (window.innerWidth <= 767 ? 50 : 80);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setWasDragging(false);
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

    setStartPos({
      x: clientX - position.x,
      y: clientY - position.y,
    });

    document.body.classList.add('no-scroll');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setWasDragging(true);
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;

    setPosition({
      x: clientX - startPos.x,
      y: clientY - startPos.y,
    });
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      snapToSide();
      document.body.classList.remove('no-scroll');
      setTimeout(() => {
        setWasDragging(false);
      }, 100);
    }
  };

  const snapToSide = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const buttonSize = getButtonSize();
    const isMobile = window.innerWidth <= 767;

    let newY = position.y;
    let newX = position.x;

    if (position.y < 30) newY = 30;
    if (position.y > windowHeight - buttonSize - 30) newY = windowHeight - buttonSize - 30;

    if (isMobile) {
      if (position.x > windowWidth - buttonSize - 10) {
        newX = windowWidth - buttonSize - 10;
      } else if (position.x < 10) {
        newX = 10;
      }
    } else if (position.x < 30) {
      newX = 30;
    } else if (position.x > windowWidth / 2) {
      newX = windowWidth - buttonSize - 30 - (isMobile ? 10 : 30);
    } else {
      newX = 30;
    }

    setPosition({ x: newX, y: newY });
  };

  const checkZoomLevel = () => {
    const calculatePosition = (zoom, isLargeScreen) => {
      if (isLargeScreen) {
        if (zoom === '1.2') return { x: 1415, y: 600 };
        if (zoom === '1.3') return { x: 1300, y: 600 };
        if (zoom === '1.5') return { x: 1100, y: 490 };
        if (zoom === '1.6') return { x: 1030, y: 460 };
      } else {
        if (zoom === '1.2') return { x: 890, y: 355 };
        if (zoom === '1.3') return { x: 840, y: 315 };
        if (zoom === '1.5') return { x: 720, y: 275 };
        if (zoom === '1.6') return { x: 675, y: 255 };
      }

      const buttonSize = isLargeScreen ? 80 : 50;
      return {
        x: window.innerWidth - buttonSize - 60,
        y: window.innerHeight - buttonSize - 100,
      };
    };

    if (window.innerWidth > 767) {
      const appElement = document.getElementById('app');
      if (appElement) {
        const computedStyle = window.getComputedStyle(appElement);
        const zoomLevel = computedStyle.zoom;

        const isLargeScreen = window.innerWidth > 1280;
        const position = calculatePosition(zoomLevel, isLargeScreen);
        setPosition(position);
      }
    } else {
      setPosition(() => ({
        x: window.innerWidth - 50 - 10,
        y: window.innerHeight - 50 - 80,
      }));
    }
  };

  useEffect(() => {
    const appElement = document.getElementById('app');

    if (appElement) {
      const observer = new MutationObserver(() => {
        checkZoomLevel();
      });

      observer.observe(appElement, {
        attributes: true, // Observes changes to attributes
        attributeFilter: ['style'], // Specifically watches for style changes
      });

      return () => observer.disconnect();
    }
  }, []); // Runs once to set up the observer

  useEffect(() => {
    const handleResize = () => {
      setPosition((prev) => {
        const buttonSize = getButtonSize();
        return {
          x: Math.min(prev.x, window.innerWidth - buttonSize - 10),
          y: Math.min(prev.y, window.innerHeight - buttonSize - 30),
        };
      });
    };

    const preventScroll = (e) => {
      e.preventDefault();
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleMouseMove, { passive: false });
      document.addEventListener('touchend', handleMouseUp);
      document.addEventListener('touchmove', preventScroll, { passive: false });
      document.addEventListener('wheel', preventScroll, { passive: false });
      document.addEventListener('keydown', preventScroll);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('touchmove', preventScroll);
      document.removeEventListener('wheel', preventScroll);
      document.removeEventListener('keydown', preventScroll);
    };
  }, [isDragging, position]);

  const handleMouseClick = (e) => {
    // Prevent unintended clicks only if dragging just stopped
    if (wasDragging) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    console.log('Floating button clicked');
  };
  const isMobile = window.innerWidth <= 767;

  if (!isMobile) return null;

  return (
    <div
      className="floating-snap-btn-wrapper"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        transition: isDragging ? 'none' : '0.3s ease-in-out',
      }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      id="accessibilityWidget" // Only assigned because we know `isMobile` is true
      onClick={handleMouseClick}
    >
      <img src={userWay} alt="a-btn" className="fab-btn" draggable="false" />
    </div>
  );
}

export default FloatingButton;
